@use '../../../style/variables';

.bulkAddStatsContainer {
	display: flex;
	flex: 6;
	width: 100%;
	height: 150vh !important;
	overflow-y: scroll;
	text-align: center;
	background-color: variables.$lightModePrimary;

	.content {

		h1 {
			color: variables.$lightModeText;
			margin-top: 100px;
			margin-bottom: 20px;
			text-align: center;
		}

		h3, p {
			text-align: center;
			color: variables.$lightModeText;
		}

		.formContainer {
			overflow-x: auto;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 20px;
			width: 105%;

			@media (max-width: 600px) {
				width: 100%;
				border-radius: 5px;
			}

			.MuiTable-root {
				width: 100%;
				min-width: 600px;
				border-collapse: collapse;
				text-align: center;

				.MuiTableRow-root {
					//&:nth-child(even) {
					//	background-color: #f2f2f2;
					//}

					.css-1ygcj2i-MuiTableCell-root {
						text-align: center !important;
					}

					.MuiTableCell-root {
						padding: 5px;
						margin-left: 3px;
						text-align: center;
						border: 1px solid variables.$lightModeText;
						color: variables.$lightModeAccent;

						&.MuiTableCell-head {
							text-align: center;
						}

						&.MuiTableCell-body {
							padding: 5px;
							margin-left: 3px;
							text-align: center;
							border: 1px solid variables.$lightModeText;
						}

						.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
							width: 80px;
							text-align: center;
						}

						.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
							color: black;
						}

						.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
							color: black;
						}

						//&:first-child {
						//	background-color: #ccc;
						//}
					}
				}

				@media (max-width: 600px) {
					min-width: 0;

					.MuiTableHead-root {
						position: sticky;
						top: 0;
						//background-color: variables.$lightModeBackground; // optional
					}

					.MuiTableRow-root {
						display: flex;
						flex-direction: column;
						border: 1px solid black;
						margin-bottom: 10px;

						.MuiTableCell-root {
							padding: 5px;
							border: none;
							border-bottom: 1px solid black;

							&:first-child {
								background-color: #ccc;
							}

							&:before {
								content: attr(data-label);
								font-weight: bold;
								display: inline-block;
								margin-right: 5px;
							}

							&.MuiTableCell-head {
								text-align: center;
							}
						}
					}
				}
			}

			.btnDeletePlayer {

				margin: 15px;
				padding: 5px;
				color: variables.$lightModePrimary;
				background-color: variables.$lightModeText;

				&:hover {
					background-color: red;
					color: black;
				}

			}



			.form-actions {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				flex-direction: column;

				.btnAddPlayer {

					display: flex;
					justify-content: center;
					margin: 25px;
					padding: 5px;
					background-color: variables.$lightModeText;

					&:hover {
						background-color: variables.$lightModeAccent;
					}

				}

				.btnSubmitPlayer {

					margin-top: 50px;

				}

			}
		}


	}

}

