@use '../../style/variables';

.new {
  flex: 6;
  display: flex;
  width: 100%;
  height: 100vh !important;
  background-color: variables.$lightModePrimary;
  overflow-y: scroll;

  .newContainer {
    flex: 6;
    text-align: center;
    height: 100vh !important;
    padding: 30px;
    background-color: variables.$lightModePrimary;

    .top {

      margin-top: 70px;

      h1 {
        color: variables.$lightModeText;
        font-size: 24px;
        text-align: center;
      }

    }

    .bottom {
      padding: 10px;
      margin: 20px 20px 100px 20px;
      display: flex;

      .content {
        flex: 4;
        text-align: center;

        form {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 40px;
          justify-content: center;
          align-items: center;

          @media (max-width: 600px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }

          .formInput {
            width: 30%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            label {
              display: flex;
              align-items: center;
              gap: 10px;
              color: variables.$lightModeText
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-radius: 5px;
              border-bottom: 1px solid gray;
              text-align: center;
              background-color: variables.$lightModePrimary;
              color: variables.$lightModeAccent;
              font-weight: bold;

              @media (max-width: 600px) {
                width: 100%;
                max-width: 200px;
                border-radius: 5px;
              }

            }

            .formInputText {
              font-size: 14px;
            }

            select {
              width: 100%;
              background-color: variables.$lightModePrimary;
              text-align: center;
              border: 2px solid variables.$lightModeAccent;
              border-radius: 5px;
              padding: 5px;
              cursor: pointer;

              @media (max-width: 600px) {
                width: 100%;
                max-width: 200px;
                border-radius: 5px;
              }

            }

          }

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background-color: green;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
