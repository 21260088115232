.auth {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.75);
	}

	.content {
		position: absolute;
		width: 100%;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: dodgerblue;

		.title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: dodgerblue;
			font-size: 16px;
		}

		form {
			display: flex;
			flex-direction: column;
			align-items: center;

			input {
				width: 400px;
				height: 35px;
				margin: 10px;
				text-align: center;
				font-size: 17px;
				//padding: 5px;
			}

			@media screen and (max-width: 600px) {
				input {
					width: 300px;
					max-width: 400px; /* set a max width to prevent the input from becoming too wide */
					height: 35px;
					margin: 10px;
					text-align: center;
					font-size: 14px; /* increase the font size */
				}
			}

			::placeholder {
				font-size: 15px;
			}

			button {
				width: 150px;
				height: 35px;
				font-size: 20px;
				border: none;
				background-color: green;
				color: white;
				font-weight: bold;
				cursor: pointer;
				margin-top: 30px;
				padding: 5px;
			}

			.errorMessage {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 10px;
				font-size: 24px;
				color: red;
				margin-top: 30px;

				.icon {
					margin-left: 5px;
				}
			}
		}

		.bottom {
			margin-top: 40px;
			font-size: 16px;

			.link {
				color: dodgerblue;
				text-decoration: none;
				cursor: pointer;
				margin-left: 5px;
			}
		}
	}
}
