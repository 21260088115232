@use '../../style/variables';

.patchNotesContainer {

	flex: 6;
	display: flex;
	width: 100%;
	height: 100vh !important;
	background-color: variables.$lightModePrimary;
	overflow-y: scroll;

	.content {

		flex: 6;
		text-align: center;
		height: 100vh !important;
		padding: 30px;
		background-color: variables.$lightModePrimary;


		.title {
			font-size: 26px;
			color: variables.$lightModeAccent;
			margin-top: 75px;
			margin-bottom: 20px;
			text-align: center;
			text-decoration: underline;
		}

		.accordionContainer {

			margin-bottom: 60px;

			.accordion {
				border: 3px solid variables.$lightModeAccent;

				.MuiPaper-root {
					background-color: variables.$lightModeAccent;
				}

				.articleHeader {
					text-align: center;
					width: 100%;
					flex-shrink: 0;
					color: variables.$lightModeAccent;
					font-size: 20px;
					font-weight: bold;
				}

				.articleContent {
					text-align: left;
					color: variables.$lightModeAccent;

					h3 {
						text-align: center;
						color: variables.$lightModeAccent;
					}

				}

			}

		}

	}

}