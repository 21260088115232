@use '../../style/variables';

.gameModeContainer {
	flex: 6;
	display: flex;
	width: 100%;
	height: 100vh !important;
	background-color: variables.$lightModePrimary;
	overflow-y: scroll;

	.content {
		flex: 6;
		text-align: center;
		height: 100vh !important;
		padding: 30px;
		background-color: variables.$lightModePrimary;

		.title {
			font-size: 26px;
			color: variables.$lightModeText;
			margin-top: 75px;
			margin-bottom: 20px;
			text-align: center;
		}

		h2 {
			color: variables.$lightModeText;
			padding: 5px;
		}

		.tableContainer {
			display: flex;
			flex-direction: column;
			height: 1400px;

			.tableWrapper {
				height: 700px;
				width: 100%;
				margin-bottom: 50px;
			}

			.MuiDataGrid-main, .MuiButtonBase-root, .MuiTablePagination-displayedRows {
				color: variables.$lightModeAccent;
			}

			.MuiDataGrid-root {
				color: variables.$lightModeAccent;
			}

		}

	}

}
