@use '../../style/variables';

.widget {
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex: 1;
	flex-wrap: wrap;
	gap: 20px;
	padding: 10px;
	-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(variables.$lightModeAccent, 0.47);
	border-radius: 10px;
	background-color: variables.$lightModePrimary;

	.center {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		.title {
			font-weight: bold;
			font-size: 16px;
			color: variables.$lightModeText;
		}

		.counter {
			font-size: 26px;
			font-weight: 300;
		}

	}

	@media screen {

		display: flex;
		justify-content: center;
		flex-direction: column;

	}

}
