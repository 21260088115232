@use '../../style/variables';

.MuiPaper-root {
	background-color: variables.$lightModePrimary !important;

	@media (max-width: 600px) {
		padding-left: 0 !important;
	}

	@media (max-width: 375px) {
		padding-left: 0 !important;
	}

	.css-1r9jet7 {
		background-color: variables.$lightModePrimary;
	}

	.navBarTitle {
		font-size: 20px;
		background-color: variables.$lightModePrimary;
	}

	.MuiCollapse-root {
		min-height: auto !important;
		height: auto;
		padding-left: 8px;
		background-color: variables.$lightModePrimary;

		@media (max-width: 600px) {
			padding-left: 0;
		}

		@media (max-width: 375px) {
			padding-left: 0;
		}

		.MuiButtonBase-root:hover {
			color: variables.$lightModePrimary;
			background-color: rgba(variables.$lightModeAccent, 0.3);
		}

	}

	.MuiListItemIcon-root, .MuiTypography-root, .MuiSvgIcon-root {
		color: variables.$lightModeAccent;

	}

	.lightModeIcon {
		color: orange !important;

		@media (max-width: 600px) {
			margin-left: 10px;
		}

		@media (max-width: 375px) {
			margin-left: 10px;
		}

	}

	.darkModeIcon {
		color: gray !important;

		@media (max-width: 600px) {
			margin-left: 10px;
		}

		@media (max-width: 375px) {
			margin-left: 10px;
		}
	}

	.lightDarkButton {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;


		.listItemButton {
			display: flex;
			justify-content: center;
			align-items: center;
		}

	}

	.lightDarkButton > * {
		margin-top: auto;
		margin-bottom: auto;
	}

}

.MuiToolbar-root, .MuiToolbar-gutters, .MuiToolbar-regular, .css-hyum1k-MuiToolbar-root {
	background-color: variables.$lightModePrimary !important;

	.MuiTypography-root {

		.navBarTitle {
			color: variables.$lightModeAccent !important;
		}

	}

}