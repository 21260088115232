// Color Variables
$primaryAccentColor: #A3C7D6;
$secondaryAccentColor: #9F73AB;
$darkModeColor: #3F3B6C;

.app.dark {

	height: 100vh;
	background-color: $darkModeColor !important;
	color: rgb(156, 156, 156);

	// dashboard homepage
	.homeContainer {
		width: 100%;
		height: 100vh !important;
		overflow-y: scroll;
		background-color: $darkModeColor !important;

		h1 {
			color: $primaryAccentColor !important;
			font-size: 30px;
		}

		h2 {
			color: $primaryAccentColor !important;
			font-size: 24px;
		}

		.widget {
			-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
			box-shadow: 2px 2px 5px 5px rgba($secondaryAccentColor, 0.47);
			background-color: $darkModeColor !important;


			.title {
				color: $primaryAccentColor !important;
			}
		}

	}

	.userProfile {
		background-color: $darkModeColor !important;
		color: $primaryAccentColor !important;

		.email, .small {
			color: $primaryAccentColor !important;
		}

	}

	// all players page
	.single {

		.allPlayersContainer {

			.content {
				background-color: $darkModeColor !important;

				.link {
					color: lightgreen !important;
					border: 1px solid lightgreen !important;
				}

				h1 {
					color: $secondaryAccentColor !important;
				}

				.MuiDataGrid-main, .MuiButtonBase-root, .MuiTablePagination-displayedRows {
					color: $primaryAccentColor !important;
				}

				.viewButton {
					color: $primaryAccentColor !important;
				}

				.MuiDataGrid-root {
					background-color: $darkModeColor !important;
				}

			}

		}

	}


	.gameModeContainer {
		background-color: $darkModeColor !important;

		.content {
			background-color: $darkModeColor !important;

			.MuiDataGrid-main, .MuiButtonBase-root, .MuiTablePagination-displayedRows {
				color: $primaryAccentColor !important;
			}

		}

	}


	.teamGameStats {
		display: flex;
		width: 100%;
		height: 100vh !important;
		overflow-y: scroll;
		background-color: $darkModeColor !important;

		.teamGameStatsContainer {
			background-color: $darkModeColor !important;

			.teamGameStatsContent {

				.title {
					color: $secondaryAccentColor !important;
				}

				.link {
					color: lightgreen !important;
					border: 1px solid lightgreen !important;
				}

				.tableContainer {
					background-color: $darkModeColor !important;
				}

				.MuiDataGrid-virtualScrollerContent.css-1kwdphh-MuiDataGrid-virtualScrollerContent {
					background-color: $darkModeColor !important;
				}

				.MuiDataGrid-main, .MuiButtonBase-root, .MuiTablePagination-displayedRows {
					color: $primaryAccentColor !important;
				}

				.viewButton {
					color: $primaryAccentColor !important;
				}

				.MuiDataGrid-root {
					background-color: $darkModeColor !important;
				}

			}

		}

	}


	// add new data pages
	.new {
		flex: 6;
		display: flex;
		width: 100%;
		height: 100vh !important;
		overflow-y: scroll;
		background-color: $darkModeColor !important;

		.newContainer {
			background-color: $darkModeColor !important;

			.top {
				h1 {
					color: $secondaryAccentColor !important;
				}
			}

			.bottom {
				-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
				box-shadow: 2px 2px 5px 5px rgba($secondaryAccentColor, 0.47);
				margin-bottom: 200px;

				.formInput {

					label {
						color: $primaryAccentColor !important;
					}

					input {

						font-size: 16px;
						font-weight: bold;
						color: $darkModeColor !important;
						background-color: $primaryAccentColor !important;

					}

				}

				button {
					background-color: green !important;
					color: $primaryAccentColor !important;
				}

			}
		}

	}

	.bulkAddStatsContainer {

		background-color: $darkModeColor !important;

		.content {

			h1, h3, p {
				color: $secondaryAccentColor !important;
			}

			.formContainer {


				.MuiTableCell-root {

					background-color: gray !important;
					border: 1px solid $primaryAccentColor !important;
					color: $darkModeColor !important;
					font-weight: bold !important;

					.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
					.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
						font-weight: bold !important;
					}

				}

				.btnAddPlayer {

					background-color: $secondaryAccentColor !important;

					&:hover {
						background-color: $secondaryAccentColor !important;
					}

				}


			}

		}

	}


	// profile pages
	.single {
		height: 100vh;

		.singleContainer {
			background-color: $darkModeColor !important;

			.itemTitle {
				color: $primaryAccentColor !important;
			}

			.itemKey {
				color: $secondaryAccentColor !important;
			}

			.itemValue {
				color: $secondaryAccentColor !important;
				font-weight: bold;
			}

			.bottom {
				background-color: $darkModeColor !important;
				padding: 20px;
				margin: 0;

				h1 {
					color: $secondaryAccentColor !important;
				}

				.link {
					color: lightgreen !important;
					border: 1px solid lightgreen !important;
				}

				.MuiDataGrid-main, .MuiButtonBase-root, .MuiTablePagination-displayedRows {
					color: $primaryAccentColor !important;
				}

			}

		}

	}

	// sidebar / navbar
	.MuiPaper-root {
		background-color: $darkModeColor !important;

		.css-1r9jet7 {
			background-color: $darkModeColor !important;
		}

		.navBarTitle {
			background-color: $darkModeColor !important;
			color: $primaryAccentColor !important;
			font-size: 20px;
		}

		.MuiCollapse-root {
			padding-left: 8px;
			background-color: $darkModeColor !important;

			.MuiButtonBase-root:hover {
				color: $darkModeColor !important;
				background-color: rgba($primaryAccentColor, 0.3) !important;
			}
		}

		.MuiListItemIcon-root, .MuiTypography-root, .MuiSvgIcon-root {
			color: $primaryAccentColor !important;
		}

		.lightModeIcon {
			color: orange !important;
		}

		.darkModeIcon {
			color: gray !important;
		}

	}

	.MuiToolbar-root, .MuiToolbar-gutters, .MuiToolbar-regular, .css-hyum1k-MuiToolbar-root {
		background-color: $darkModeColor !important;

		.MuiTypography-root {
			color: $primaryAccentColor !important;

		}

	}

	.charts {
		background-color: $darkModeColor !important;
		padding: 0 !important;

		.chart {
			background-color: $darkModeColor !important;

			.content {

				.chartContainer {

					// barchart dark theme
					& .recharts-cartesian-grid-horizontal line,
					& .recharts-cartesian-grid-vertical line {
						stroke: $primaryAccentColor !important;
					}

					& .recharts-yaxis,
					& .recharts-yaxis .recharts-cartesian-axis-line {
						stroke: $primaryAccentColor !important;
					}

					& .recharts-yaxis .recharts-cartesian-axis-tick line {
						stroke: $primaryAccentColor !important;
					}

					& .recharts-tooltip {
						background-color: $primaryAccentColor !important;
						border: 3px solid $primaryAccentColor !important;
					}

					& .recharts-tooltip-label {
						color: $secondaryAccentColor !important;
					}

				}

				.barClass {
					font-size: 10px;
				}
			}
		}
	}

	.userProfileContainer {

		.userProfileContent {
			background-color: $darkModeColor !important;

			.title, .email, .small {
				color: $primaryAccentColor !important;
			}

		}

	}


	.patchNotesContainer {
		background-color: $darkModeColor !important;
		width: 100%;
		height: 100vh !important;
		overflow-y: scroll;

		.content {
			background-color: $darkModeColor !important;

			.title {
				color: $secondaryAccentColor !important;
			}

			.accordionContainer {
				color: white;

				.articleContent {
					color: $primaryAccentColor !important;
				}

			}

		}

	}

	.footerContent {
		background-color: $darkModeColor !important;
	}

}
