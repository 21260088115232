@use '../../style/variables';

.footerContent {
	background-color: variables.$lightModePrimary !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	font-size: 16px;

	.heart {
		color: red;
		margin-right: 5px;
		margin-left: 5px;
	}

	.icon {
		color: dodgerblue;
		margin-right: 5px;
		margin-left: 5px;
	}

	.patchNotes {
		color: variables.$lightModeAccent;
		cursor: pointer;
	}

	.patchNotes:hover {
		color: variables.$lightModeText;
		cursor: pointer;
	}

}
