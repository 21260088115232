@use '../../style/variables';

.single {
	display: flex;
	width: 100%;

	.singleContainer {
		flex: 6;
		background-color: variables.$lightModePrimary;

		.top {
			padding: 20px;
			display: flex;
			gap: 20px;
			margin-top: 55px;

			.profileContent {
				flex: 1;
				padding: 20px;

				.item {
					display: flex;
					text-align: center;
					justify-content: center;

					.itemTitle {
						color: variables.$lightModeAccent;
					}

					.itemKey {
						font-size: 16px;
						color: variables.$lightModeText;
					}

					.itemValue {
						font-size: 16px;
						color: variables.$lightModeText;
						font-weight: bold;
					}

				}
			}

		}

		.bottom {
			padding: 20px;
			margin: 10px 20px;

			.title {
				font-size: 20px;
				color: variables.$lightModeText;
				margin-bottom: 20px;
				text-align: center;
			}

			.link {
				font-size: 16px;
				color: green;
				border-radius: 5px;
				border: 1px solid green;
				text-align: center;
				text-decoration: none;
				padding: 5px;
				cursor: pointer;
			}

			.MuiDataGrid-main, .MuiButtonBase-root, .MuiTablePagination-displayedRows {
				color: variables.$lightModeAccent;
			}

		}
	}

}
