
.profile-details {

    .itemTitle {
        margin-bottom: 10px;
        color: #555;
    }

    .detailItem {
        margin-bottom: 10px;
        font-size: 14px;

        .itemKey {
            font-weight: bold;
            color: gray;
            margin-right: 5px;
        }

        .itemValue {
            font-weight: 300;
        }

    }

}

