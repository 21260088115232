@use '../../style/variables';

.single {
    display: flex;
    width: 100%;

    .allPlayersContainer {
        flex: 6;
        margin-top: 50px;

        .content {
            height: 160vh !important;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(variables.$lightModeAccent, 0.47);
            padding: 30px;
            background-color: variables.$lightModePrimary;

            .title {
                font-size: 24px;
                color: variables.$lightModeText;
                margin-bottom: 20px;
                text-align: center;
            }

            .context {
                color: variables.$lightModeText;
                text-align: center;
            }

            .linkContainer {

                display: flex;
                flex-direction: column;
                padding: 5px;

                .link {
                    font-size: 16px;
                    color: darkgreen;
                    border-radius: 5px;
                    border: 1px solid darkgreen;
                    text-align: center;
                    text-decoration: none;
                    padding: 5px;
                    cursor: pointer;
                    width: 8%;

                    @media (max-width: 600px) {
                        width: 100%;
                    }

                }

                .secondLink {
                    margin-top: 10px;
                    width: 25%;

                    @media (max-width: 600px) {
                        margin-top: 10px;
                        width: 100%;
                    }

                }

            }

            .dataGridTable {
                height: 1000px;
                width: 100%;
                text-align: center;
                margin-top: 20px;

                .MuiDataGrid-main, .MuiButtonBase-root, .MuiTablePagination-displayedRows {
                    color: variables.$lightModeAccent;
                }

                .viewButton {
                    color: variables.$lightModePrimary;
                    background-color: variables.$lightModeAccent;
                    padding: 5px;
                    border-radius: 15%;
                    text-align: center;
                }

                .MuiDataGrid-root {
                    color: variables.$lightModeAccent;
                }

            }

        }
    }




}