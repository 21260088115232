.singlePlayerTable {

	.modal-form  {

		background-color: rgba(0, 0, 0, 0.01);

		.form-control {

			text-align: center;

		}

	}

	//.MuiModal-root {
	//  background-color: #fff;
	//
	//  .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
	//    background-color: rgba(green, .2) !important;
	//  }
	//}

}