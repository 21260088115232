@use '../../style/variables';

.userProfileContainer {

	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: variables.$lightModePrimary;

	.userProfileContent {

		flex: 6;
		width: 100%;
		height: 100%;
		top: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: center;
		background-color: variables.$lightModePrimary;

		.title {
			color: variables.$lightModeText;
		}

		.email, .small {
			color: variables.$lightModeAccent;
		}

		.small {
			font-size: 10px;
		}

	}

}
