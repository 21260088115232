.welcome {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.75)
	}

	.content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: center;
		color: dodgerblue;

		.title {

			.title-grid {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 10px;
				font-size: 16px;
			}

			.icon-title {
				color: green;
			}

			.email-title {
				font-size: 16px;
				font-style: italic;
				font-weight: bold;
			}

			.right-title {
				font-size: 20px;
			}

			@media screen and (max-width: 450px) {
				.right-title {
					font-size: 14px;
					padding: 5px;
				}
			}

		}

		.bottom-content {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			h2 {
				color: dodgerblue;
			}

			.left-game, .right-game {
				text-align: center;
				padding: 10px;
				border-radius: 5%;
			}

			.link {
				display: flex;
				flex-direction: column;
				text-align: center;
				justify-content: center;
				align-items: center;
				text-decoration: none;
				color: black;
				cursor: pointer;
			}

			.gameImage {
				max-width: 300px;
				width: 100%;
				max-height: 300px;
				height: 100%;
				border-radius: 5%;
			}

		}

	}

}