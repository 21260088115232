@use '../../style/variables';

.home {
	flex: 6;
	display: flex;
	width: 100%;
	height: 100vh !important;
	background-color: variables.$lightModePrimary;
	overflow-y: scroll;

	.homeContainer {
		flex: 8;
		text-align: center;
		height: 100vh !important;
		padding: 30px;
		background-color: variables.$lightModePrimary;

		h1 {
			margin-top: 65px;
			text-align: center;
			padding: 10px;
			color: variables.$lightModeText;
		}

		h2 {
			margin-left: 25px;
			color: variables.$lightModeText;
		}

		.widgets {
			display: flex;
			justify-content: center;
			flex-direction: row;
			flex-wrap: wrap;
			padding: 30px;
			gap: 20px;
		}

		.teamGameStats {
			-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
			box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
			padding: 20px;
			margin: 20px;
		}

		.charts {
			padding: 0 !important;
			background-color: variables.$lightModePrimary !important;
		}

		.listContainer {
			-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
			box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
			padding: 20px;
			margin: 20px;

			.listTitle {
				font-weight: 500;
				color: gray;
				margin-bottom: 15px;
			}
		}

	}
}
