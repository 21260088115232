@use '../../style/variables';

.teamGameStats {
	display: flex;
	width: 100%;
	height: 100vh !important;
	overflow-y: scroll;
	background-color: variables.$lightModePrimary;

	.teamGameStatsContainer {
		flex: 6;
		background-color: variables.$lightModePrimary;

		.teamGameStatsContent {
			padding: 20px;
			margin: 10px 20px;

			h1 {
				color: variables.$lightModeText;
				margin-top: 75px;
				margin-bottom: 20px;
				text-align: center;
			}

			.link {
				font-size: 16px;
				color: darkgreen;
				border-radius: 5px;
				border: 1.5px solid darkgreen;
				text-align: center;
				text-decoration: none;
				padding: 5px;
				cursor: pointer;

				@media (max-width: 600px) {
					display: flex;
					justify-content: center;
				}

			}

			.tableContainer {
				display: flex;
				flex-direction: column;
				height: 2800px;

				h2 {
					text-align: center;
					color: variables.$lightModeText;
				}

				.tableWrapper {
					height: 700px;
					width: 100%;
					margin-bottom: 50px;
				}

				.MuiDataGrid-main, .MuiButtonBase-root, .MuiTablePagination-displayedRows {
					color: variables.$lightModeAccent;
				}

				.MuiDataGrid-root {
					color: variables.$lightModeAccent;
				}

			}

		}
	}

	.title {
		font-size: 16px;
		color: lightgray;
		margin-bottom: 20px;
	}
}