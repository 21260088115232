.pageNotFound {

	flex: 6;
	display: flex;
	width: 100%;
	height: 100vh !important;

	.content {

		margin-top: 100px;
		flex: 6;
		text-align: center;
		height: 100vh !important;
		padding: 30px;

	}

}