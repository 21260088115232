@use '../../style/variables';

.chart {
  background-color: variables.$lightModePrimary;
  flex: 4;
  color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 100px;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .chartContainer {
        display: flex;
        flex-direction: column;
        height: 1400px;
        justify-content: center;
        margin-bottom: 30px;

        @media (max-width: 600px) {
          height: 60%;
        }

        @media (max-width: 375px) {
          height: 40%;
        }

        .chartWrapper {
          height: 100%;
          max-height: 600px;
          margin-bottom: 50px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          max-width: 100%; // set max-width to 100% to prevent overflow

          h2 {
            text-align: center;
            color: variables.$lightModeText;
          }

        }

        & .recharts-cartesian-grid-horizontal line,
        & .recharts-cartesian-grid-vertical line {
          stroke: variables.$lightModeText;
        }

        & .recharts-yaxis,
        & .recharts-yaxis .recharts-cartesian-axis-line {
          stroke: variables.$lightModeText;
        }

        & .recharts-yaxis .recharts-cartesian-axis-tick line {
          stroke: variables.$lightModeText;
        }

        & .recharts-tooltip {
          background-color: variables.$lightModeAccent;
          border: 3px solid variables.$lightModeAccent;
        }

        & .recharts-tooltip-label {
          color: variables.$lightModeText;
        }

        .hidden {
          display: none;
        }


      }



      .xAxisText {
        font-size: 5px;
      }

      //.chartItself {
      //  height: 800px;
      //  width: auto;
      //}

      .barClass {
        font-size: 10px;
      }

    }

  .chartGrid {
    stroke: lightgray;
  }

}